import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import CenteredVideo from '../components/CenteredVideo'
import Footer from '../components/Footer'
import './SingleProject.css'
import './SingleClient.css'

export class SingleClientTemplate extends Component {
  render() {
    const { title, excerpt, video, youtubeVideoId, items } = this.props
    return (
      <Fragment>
        <CenteredVideo
          title={title}
          excerpt={excerpt}
          video={video}
          youtubeVideoId={youtubeVideoId}
          items={items}
          backlink="/back-to-client/"
        />
        <Footer />
      </Fragment>
    )
  }
}

const fiterData = (filterArr, data) => {
  let items = [],
    filters = []

  if (filterArr.length) {
    for (let i in filterArr) {
      filters.push(filterArr[i].link)
    }

    // This foor loop is the order of strings
    for (let j in filters) {
      // this loops through the data and pushes it at the j index
      for (let i in data) {
        // see j and i index mix up
        if (filters[j].includes(data[i].node.fields.slug)) {
          items.push({
            ...data[i].node.fields,
            ...data[i].node.frontmatter
          })
        }
      }
    }
  }

  return items
}

const SingleClient = ({ data: { client, data } }) => {
  const items = fiterData(client.frontmatter.items, data.edges)
  return (
    <Layout
      meta={client.frontmatter.meta || false}
      title={client.frontmatter.title || false}
    >
      <SingleClientTemplate
        {...client}
        {...client.frontmatter}
        items={items}
        body={client.html}
      />
    </Layout>
  )
}

export default SingleClient

export const pageQuery = graphql`
  query SingleClient($id: String!) {
    client: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        excerpt
        video
        youtubeVideoId
        items {
          link
        }
      }
    }

    data: allMarkdownRemark(
      filter: {
        fields: {
          contentType: {
            in: [
              "events"
              "droneAerials"
              "motionGraphics"
              "businessStories"
              "promos"
              "photography"
              "posts"
              "tourism"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            contentType
          }
          frontmatter {
            preview
            featuredImage
            title
            excerpt
            video
            youtubeVideoId
          }
        }
      }
    }
  }
`
